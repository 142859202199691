import React from 'react';

const Authors = props => (
  <div className="authors">
    <div className="text">
      <img src={props.img} alt="" />
      <div className="">
        <strong>{props.title}</strong>
      </div>
      <div className="small text-muted">{props.description}</div>
    </div>
  </div>
);

export default Authors
