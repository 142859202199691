import React from 'react';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';
import * as PropTypes from "prop-types";

import Layout from '../components/layout';
import Authors from '../components/authors';

import iconblockdata from '../img/2x/icon-blockdata.png';
import richard from '../img/team/richard-dennis.jpg';
import gareth from '../img/team/gareth-owenson.jpg';
import temtumcircle from '../img/temtum-logo-circle.png';

const propTypes = {
  data: PropTypes.object.isRequired,
};

class Research extends React.Component {
  render() {
    const { data } = this.props;
    const postsEdges = data.posts.edges;

    return (
      <Layout>
        <div className="skipScroll">
          <Helmet
            title={'Academic Blockchain Publications | Cryptocurrency Research Documents'}
            meta={[
              {
                name: 'description',
                content: 'With 2 PhDs in the temtum team, multiple papers we`ve published on peer to peer networks & blockchain over the course of our development.'
              }
            ]}
          />
          <div>
            <div className="hd-space"/>
            <div className="page">
              <section className="text-white skipScroll bg2X bg-research">
                <div className="darker video-overlay"></div>
                <div className="wow3 video-overlay"></div>
                <div className="container">
                  <div className="row" data-aos="fade-in" data-aos-duration="1200">
                    <div className="col col-12 col-sm-6 col-md-6">
                      <h3 className="display-2 font-weight-normal mb-3 headline red pb-3">Blockchain Research</h3>
                      <p className="lead">
                        We've spent the past 5 years researching Blockchain and boast the only Blockchain PhD as part of our founding team. This research led to the development of the superior Temporal Blockchain, compared below to what is currently considered the leading technology in the industry.
                      </p>
                    </div>
                    <div className="col col-12 col-sm-6 col-md-6 mx-auto text-center">
                      <div className="container">
                        <div className="row text-center text-white">
                          <div className="col">
                            <div className="countBox">
                              <img src={iconblockdata} className="" alt="icon"/>
                              <span className="timer count-title">
                          <span className="counter" data-counterup-time="1000" data-counterup-delay="200" data-counterup-beginat="0">5</span>
                        </span>
                              <p className="count-text">Academic years of research</p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="countBox">
                              <img src={iconblockdata} className="" alt="icon"/>
                              <span className="timer count-title">
                          <span className="counter" data-counterup-time="1000" data-counterup-delay="400" data-counterup-beginat="0">12</span>
                        </span>
                              <p className="count-text ">Published research papers</p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="countBox">
                              <img src={iconblockdata} className="" alt="icon"/>
                              <span className="timer count-title">
                          <span className="counter" data-counterup-time="1000" data-counterup-delay="600" data-counterup-beginat="0">3</span>
                        </span>
                              <p className="count-text ">World renowned cryptography experts</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="skipScroll ">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 card-deckX plain-cards blog-list">
                      <div>
                        <h2 className="display-3 font-weight-bold mb-5 pb-3 headline red">
                          Research Papers
                        </h2>
                        <div>
                          {
                            postsEdges.map( ( { node } ) => ( <div key={node.id}>
                              <div className="card no-border mb-3">
                                <div className="card-body">
                                  <h4 className="card-title font-weight-bolder">
                                    <Link to={node.fields.slug} className="card-title ">
                                      {node.frontmatter.title}
                                    </Link>
                                  </h4>
                                  <ul className="list-inline small mb-0">
                                    <li className="list-inline-item">
                                      <span className="card-text text-muted">in</span> <span className="upper text-blue font-weight-bold">Research Papers</span>
                                    </li>
                                    <li className="list-inline-item">
                                      <span className="card-text">
                                        <span className="text-muted">
                                          <i className="fa fa-clock mr-2"></i>
                                          {node.frontmatter.date}
                                        </span>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="author-box-small mt-3 pt-3 mb-0">
                                    <img
                                      src={temtumcircle}
                                      alt="icon"
                                      width={44} height={44}
                                    />
                                    <div className="font-weight-bold nameX small">{node.frontmatter.author}</div>
                                    <span className="text-muted small">{node.frontmatter.author_sub}</span>
                                  </div>
                                </div>
                              </div>
                            </div> ) )
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <h3 className="display-4 font-weight-bold mb-5 pb-3 headline blue">
                        Contributors
                      </h3>

                      <Authors img={richard} title="Richard Dennis, Msc" description="Founder & CEO"/>
                      <Authors img={gareth} title="Dr Gareth Owenson PhD, BSc" description="CISO"/>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

Research.propTypes = propTypes;

export default Research;

export const query = graphql `
query ResearchQuery {
  posts: allMarkdownRemark(limit: 9, sort: {fields: [frontmatter___date], order: DESC}, filter: {frontmatter: {posttype: {regex: "/research/"}}}) {
    totalCount
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "DD MMMM, YYYY")
          category
          posttype
          author
          author_sub
          author_image
        }
        excerpt
        wordCount {
          paragraphs
          sentences
          words
        }
      }
    }
  }
}
`;